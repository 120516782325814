<template>
  <div class="flex" style="height: 100vh">
    <div
      class="flex flex-col justify-around hidden md:block md:w-1/3 bg-cream"
      style="height: 100%"
    >
      <div class="w-full h-1/3"></div>
      <div class="w-full h-1/3">
        <img
          src="/static/lif-logo.png"
          class="w-1/2 mx-auto my-auto"
          alt="Logo LIF"
        />
      </div>
      <div class="w-full h-1/3 flex justify-center items-end pb-4">
        <img
          src="/static/ticktaps-logo.svg"
          class="w-1/3"
          alt="Logo Ticktaps"
        />
      </div>
    </div>
    <div
      class="relative block md:flex md:flex-col w-full md:w-2/3 center bg-cream overflow-y-auto"
      :style="`height: 100%; background-image: url('/static/liga-bg.png'); background-size: cover; background-position: center; `"
    >
      <!-- <div class="md:hidden">
        <router-link to="/inicio">
          <div class="w-1/3 my-4 mx-auto object-scale-down">
            <img src="/static/lif-logo.png" />
          </div>
        </router-link>
      </div> -->
      <div class="relative mx-auto w-11/12">
        <div class="relative w-full rounded-3xl bg-white shadow-md">
          <div class="p-4 sm:p-8">
            <h1 class="title-text">Registro de academias</h1>
            <div
              class="flex flex-col justify-center mt-2"
              v-if="!formSubmitted"
            >
              <div class="steps-content">
                <Form
                  ref="AcademyForm"
                  :disableInputs="false"
                  @submit="register"
                />

                <div class="flex justify-end items-center mt-5">
                  <t-button @click="isValid"
                    >Guardar <t-loader v-if="isLoading" extraClass="ml-2"
                  /></t-button>
                </div>
              </div>
            </div>
            <template v-else>
              <div class="flex flex-col justify-center items-center">
                <icon
                  icon="check-circle"
                  class="mx-auto my-auto text-8xl"
                  style="color: #006400"
                ></icon>
                <h1 class="subtitle-text mt-5 text-xl">
                  ¡Bienvenido(a) a la Liga Internacional!
                </h1>
                <h1 class="subtitle-text mt-5 text-lg">
                  Tu academia está siendo verificada. Una vez finalice el
                  proceso de revisión, recibirás un correo para completar el
                  registro de la plantilla de jugadores.
                </h1>
                <h1 class="subtitle-text mt-5 text-lg">
                  ¡Mi pasión es el fútbol, mi futuro es
                  <span class="text-red-1100"> Vinotinto</span>!
                </h1>
                <t-button
                  class="mx-auto mt-3 md:my-auto"
                  @click="$router.push('/inicio')"
                  >Volver al inicio</t-button
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./Form.vue"
const notify = () => import("@/utils/notify.js")

export default {
  name: "AcademySignup",
  props: {
    disableInputs: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      formSubmitted: false
    }
  },
  components: {
    Form
  },
  methods: {
    isValid() {
      this.$refs.AcademyForm.isValid()
    },
    register() {
      this.isLoading = true
      const payload = this.$refs.AcademyForm.form
      const contacts = this.$refs.AcademyForm.academyContacts

      this.$store
        .dispatch("global/create", {
          payload: {
            coordinator: payload.coordinator,
            contacts: JSON.stringify(contacts),
            name: payload.name,
            categories: payload.categories,
            address1: payload.address1,
            address2: payload.address2,
            email: payload.email,
            instagram: payload.instagram,
            url_logo: payload.url_logo,
            url_field: payload.url_field
          },
          route: "/academy/save"
        })
        .then((response) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("academy", response.code, this.$snotify)
          })
          if ([200, 201].includes(response.code)) {
            this.formSubmitted = true
          }
        })
        .catch((error) => {
          console.log("academy register error:", error)
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  }
}
</script>
