<template>
  <div>
    <div class="flex flex-col md:flex-row mt-5 items-end">
      <t-input-group
        :feedback="
          showValidation && errors.first('name') ? errors.first('name') : ''
        "
        :variant="showValidation && errors.first('name') ? 'danger' : ''"
        class="w-full md:w-1/2 md:ml-2 md:mr-2"
        :label="'Nombre de la academia'"
      >
        <t-input
          v-validate="'required|max:200'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.name"
          name="name"
          placeholder="Ingrese el nombre"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('name') ? 'danger' : ''"
        />
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('coordinator')
            ? errors.first('coordinator')
            : ''
        "
        :variant="showValidation && errors.first('coordinator') ? 'danger' : ''"
        class="w-full md:w-1/2 md:ml-2 md:mr-2"
        :label="'Coordinador'"
      >
        <t-input
          v-validate="'required'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.coordinator"
          name="coordinator"
          placeholder="Ingrese el nombre del coordinador"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="
            showValidation && errors.first('coordinator') ? 'danger' : ''
          "
        />
      </t-input-group>
    </div>

    <div class="flex flex-col md:flex-row items-end justify-around my-2">
      <t-input-group
        :feedback="
          showValidation && errors.first('url_logo')
            ? errors.first('url_logo')
            : ''
        "
        :variant="showValidation && errors.first('url_logo') ? 'danger' : ''"
        class="w-full md:w-1/3 md:mr-0 md:pr-2"
        :label="'Foto del escudo'"
      >
        <cropper-upload
          :file.sync="form.url_logo"
          :externalUrl="
            typeof form.url_logo === 'string' ? form.url_logo : null
          "
          title="Foto del escudo"
          :aspectRatio="1"
          height="250px"
          backgroundColor="bg-white"
        />
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('url_field')
            ? errors.first('url_field')
            : ''
        "
        :variant="showValidation && errors.first('url_field') ? 'danger' : ''"
        class="w-full md:w-1/3 md:mr-0 md:pr-2"
        :label="'Foto de la sede'"
      >
        <cropper-upload
          :file.sync="form.url_field"
          :externalUrl="
            typeof form.url_field === 'string' ? form.url_field : null
          "
          title="Foto de la sede"
          :aspectRatio="16 / 9"
          height="250px"
          backgroundColor="bg-white"
        />
      </t-input-group>
    </div>

    <div class="flex flex-col md:flex-row items-end my-2">
      <t-input-group
        :feedback="
          showValidation && errors.first('address1')
            ? errors.first('address1')
            : ''
        "
        :variant="showValidation && errors.first('address1') ? 'danger' : ''"
        class="w-full md:mr-2"
        :label="'Dirección'"
      >
        <t-input
          v-validate="'required'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.address1"
          name="address1"
          placeholder="Estado, ciudad"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('address1') ? 'danger' : ''"
        />
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('address2')
            ? errors.first('address2')
            : ''
        "
        :variant="showValidation && errors.first('address2') ? 'danger' : ''"
        class="w-full md:mr-2"
      >
        <t-input
          classes="bg-transparent"
          v-model="form.address2"
          name="address2"
          placeholder="Calle, edificio, etc."
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('address2') ? 'danger' : ''"
        />
      </t-input-group>
    </div>

    <div class="flex flex-col md:flex-row items-start mt-5">
      <t-input-group
        :feedback="
          showValidation && errors.first('categories')
            ? errors.first('categories')
            : ''
        "
        :variant="showValidation && errors.first('categories') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-4"
        :label="'Categorías'"
      >
        <t-rich-select
          v-validate="'required'"
          data-vv-validate-on="input"
          v-model="form.categories"
          name="categories"
          id="categories"
          placeholder="Seleccione una opción"
          :disabled="isLoading || disableInputs"
          noResultsText="No se encontraron resultados"
          searchBoxPlaceholder="Busca..."
          :options="categories"
          textAttribute="name"
          valueAttribute="id"
          :multiple="true"
          :closeOnSelect="false"
          :clearable="true"
          :variant="
            showValidation && errors.first('categories') ? 'danger' : ''
          "
        ></t-rich-select>
      </t-input-group>
    </div>

    <h1 class="subtitle-text mt-5">Información de contacto</h1>

    <div class="flex flex-col md:flex-row items-start mt-5">
      <t-input-group
        :feedback="
          showValidation && errors.first('email') ? errors.first('email') : ''
        "
        :variant="showValidation && errors.first('email') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-2"
        :label="'Correo electrónico'"
      >
        <t-input
          v-validate="'required|email'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.email"
          name="email"
          placeholder="Ingrese el correo electrónico"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="email"
          :variant="showValidation && errors.first('email') ? 'danger' : ''"
        />
      </t-input-group>
      <t-input-group
        :feedback="
          showValidation && errors.first('instagram')
            ? errors.first('instagram')
            : ''
        "
        :variant="showValidation && errors.first('instagram') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-2"
        :label="'Usuario de Instagram'"
      >
        <t-input
          classes="bg-transparent"
          v-model="form.instagram"
          name="instagram"
          placeholder="Ingrese el usuario de Instagram"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('instagram') ? 'danger' : ''"
        />
      </t-input-group>
    </div>

    <div class="mt-5">
      <div class="flex justify-start">
        <h1 class="subtitle-text">Contactos de la academia</h1>
        <icon
          v-if="!disableInputs"
          style="cursor: pointer; width: 28px; height: 28px"
          icon="plus"
          class="rounded-full bg-primary-700 text-white text-3xl p-2 my-auto ml-3"
          @click="
            academyContacts = academyContacts.concat([
              {
                key: academyContacts.length,
                name: '',
                phone: ''
              }
            ])
          "
        />
      </div>

      <div
        v-for="element in academyContacts"
        :key="
          element.id
            ? new Date().toLocaleDateString() + element.id
            : new Date().toLocaleDateString() + element.key
        "
      >
        <div class="flex md:hidden my-3">
          <div class="w-1/6 my-auto mx-auto">
            <icon
              v-if="!disableInputs"
              style="cursor: pointer; width: 28px; height: 28px"
              icon="times"
              class="rounded-full bg-primary-700 text-white text-3xl p-2 my-auto ml-3"
              @click="deleteAcademyContact(element)"
            />
          </div>
          <div class="w-5/6">
            <t-input-group
              class="w-full mx-1"
              :label="'Nombre'"
              :feedback="
                showValidation &&
                errors.first(
                  element.id
                    ? element.id + 'academyContact-name'
                    : element.key + 'academyContact-name'
                )
                  ? errors.first(
                      element.id
                        ? element.id + 'academyContact-name'
                        : element.key + 'academyContact-name'
                    )
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(
                  element.id
                    ? element.id + 'academyContact-name'
                    : element.key + 'academyContact-name'
                )
                  ? 'danger'
                  : ''
              "
            >
              <t-input
                v-validate="'simple_required'"
                data-vv-validate-on="input"
                classes="bg-transparent"
                v-model="element.name"
                :name="
                  element.id
                    ? element.id + 'academyContact-name'
                    : element.key + 'academyContact-name'
                "
                placeholder="Ingrese el nombre del contacto"
                autocomplete="off"
                :disabled="isLoading || disableInputs"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(
                    element.id
                      ? element.id + 'academyContact-name'
                      : element.key + 'academyContact-name'
                  )
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>
            <t-input-group
              class="w-full md:w-1/2 mx-1"
              :label="'Teléfono'"
              :feedback="
                showValidation &&
                errors.first(
                  element.id
                    ? element.id + 'academyContact-phone'
                    : element.key + 'academyContact-phone'
                )
                  ? errors.first(
                      element.id
                        ? element.id + 'academyContact-phone'
                        : element.key + 'academyContact-phone'
                    )
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(
                  element.id
                    ? element.id + 'academyContact-phone'
                    : element.key + 'academyContact-phone'
                )
                  ? 'danger'
                  : ''
              "
            >
              <t-input
                v-validate="'simple_required|phone'"
                data-vv-validate-on="input"
                classes="bg-transparent"
                v-model="element.phone"
                :name="
                  element.id
                    ? element.id + 'academyContact-phone'
                    : element.key + 'academyContact-phone'
                "
                placeholder="Ingrese el teléfono del contacto"
                autocomplete="off"
                :disabled="isLoading || disableInputs"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(
                    element.id
                      ? element.id + 'academyContact-phone'
                      : element.key + 'academyContact-phone'
                  )
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>
          </div>
        </div>
        <div class="hidden md:flex flex-col md:flex-row items-end">
          <div class="flex md:w-1/2">
            <icon
              v-if="!disableInputs"
              style="cursor: pointer; width: 28px; height: 28px"
              icon="times"
              class="rounded-full bg-primary-700 text-white text-3xl p-2 mt-9 ml-3"
              @click="deleteAcademyContact(element)"
            />
            <t-input-group
              class="w-full mx-1"
              :label="'Nombre'"
              :feedback="
                showValidation &&
                errors.first(
                  element.id
                    ? element.id + 'academyContact-name'
                    : element.key + 'academyContact-name'
                )
                  ? errors.first(
                      element.id
                        ? element.id + 'academyContact-name'
                        : element.key + 'academyContact-name'
                    )
                  : ''
              "
              :variant="
                showValidation &&
                errors.first(
                  element.id
                    ? element.id + 'academyContact-name'
                    : element.key + 'academyContact-name'
                )
                  ? 'danger'
                  : ''
              "
            >
              <t-input
                v-validate="'simple_required'"
                data-vv-validate-on="input"
                classes="bg-transparent"
                v-model="element.name"
                :name="
                  element.id
                    ? element.id + 'academyContact-name'
                    : element.key + 'academyContact-name'
                "
                placeholder="Ingrese el nombre del contacto"
                autocomplete="off"
                :disabled="isLoading || disableInputs"
                type="text"
                :variant="
                  showValidation &&
                  errors.first(
                    element.id
                      ? element.id + 'academyContact-name'
                      : element.key + 'academyContact-name'
                  )
                    ? 'danger'
                    : ''
                "
              />
            </t-input-group>
          </div>

          <t-input-group
            class="ml-auto w-full md:w-1/2 mx-1"
            :label="'Teléfono'"
            :feedback="
              showValidation &&
              errors.first(
                element.id
                  ? element.id + 'academyContact-phone'
                  : element.key + 'academyContact-phone'
              )
                ? errors.first(
                    element.id
                      ? element.id + 'academyContact-phone'
                      : element.key + 'academyContact-phone'
                  )
                : ''
            "
            :variant="
              showValidation &&
              errors.first(
                element.id
                  ? element.id + 'academyContact-phone'
                  : element.key + 'academyContact-phone'
              )
                ? 'danger'
                : ''
            "
          >
            <t-input
              v-validate="'simple_required|phone'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="element.phone"
              :name="
                element.id
                  ? element.id + 'academyContact-phone'
                  : element.key + 'academyContact-phone'
              "
              placeholder="Ingrese el teléfono contacto"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(
                  element.id
                    ? element.id + 'academyContact-phone'
                    : element.key + 'academyContact-phone'
                )
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const notify = () => import("@/utils/notify.js")
export default {
  name: "AcademyForm",
  props: {
    disableInputs: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        name: null,
        coordinator: null,
        address1: null,
        address2: null,
        categories: [],
        phone: null,
        email: null,
        url_logo: null,
        url_field: null
      },
      showValidation: false,
      isLoading: false,
      academyContacts: [],
      categories: []
    }
  },
  methods: {
    deleteAcademyContact(element) {
      if (element.key || element.key === 0) {
        this.academyContacts = this.academyContacts.filter(
          (x) => x.key !== element.key
        )
      }
    },
    getItems(route, object) {
      this.$store
        .dispatch("global/getItems", {
          route: route + "/all",
          noPaginate: true,
          isMaster: true,
          orderKey: "name",
          orderValue: "asc",
          params: {
            active: 1
          }
        })
        .then((response) => {
          this[object] = response.data
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    isValid() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid === true) {
          if (!this.form.url_logo) {
            this.$snotify.error("La foto del escudo es obligatoria", "Error", {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            })
            return
          }
          if (!this.form.url_field) {
            this.$snotify.error("La foto de la sede es obligatoria", "Error", {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            })
            return
          }
          this.$emit("submit")
        }
      })
    }
  },
  created() {
    this.showValidation = true
    this.getItems("/category", "categories")
  },
  watch: {}
}
</script>
